import {
  FormControl,
  FormLabel,
  Button,
  FormHelperText,
  VStack,
  Container,
  Stack
} from '@chakra-ui/react';
import { Formik, Field, Form, FormikProps } from 'formik';
import { useNewTeacherContext } from '../../contexts/NewTeacherProvider';
import Avatar from 'react-avatar-edit';

interface FormValues {
  profilePictureBase64: string;
}

const PictureData = () => {
  const { teacher, updateTeacher } = useNewTeacherContext();

  const handleBack = async () => {
    await updateTeacher({ step: teacher.step - 1 });
  };

  const handleSubmit = async (values: any, actions: any) => {
    await updateTeacher({ step: teacher.step + 1, ...values });
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ profilePictureBase64: teacher.profilePictureBase64 }}
      onSubmit={handleSubmit}>
      {(props: FormikProps<FormValues>) => {
        const { isSubmitting, setFieldValue, values } = props;
        const handleOnClose = async () => {
          await setFieldValue('profilePictureBase64', undefined);
        };

        const handleOnCrop = async (cropPicture: string) => {
          await setFieldValue('profilePictureBase64', cropPicture);
        };

        return (
          <Form>
            <VStack spacing="12px" margin="1rem">
              <Field name="profilePictureBase64">
                {({}: { field: any }) => (
                  <FormControl isRequired>
                    <FormLabel>Foto de perfíl</FormLabel>
                    <FormHelperText textAlign="left">
                      Tips para una buena foto de perfil:
                      <br />
                      - Buena calidad
                      <br />- Verte bien <br />- Transmitir alegria o confianza <br />- Que sea
                      individual <br />
                    </FormHelperText>
                    <Container display="flex" justifyContent="center" mt={5} width="100%">
                      <Avatar
                        width={300}
                        height={300}
                        exportSize={400}
                        onCrop={handleOnCrop}
                        onClose={handleOnClose}
                        label="Elegir una foto"
                        src={teacher.profilePictureBase64}
                        exportAsSquare
                      />
                    </Container>
                  </FormControl>
                )}
              </Field>
              <Stack display="flex" flexDirection="row" spacing={6}>
                <Button mt={4} variant="outline" colorScheme="blue" onClick={handleBack}>
                  Atras
                </Button>
                <Button
                  mt={4}
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  isDisabled={!values.profilePictureBase64}
                  type="submit">
                  Siguiente
                </Button>
              </Stack>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PictureData;
