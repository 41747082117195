export const sendAdditionalCourses = (email: string, message: string) => {
  const formdata = new FormData();

  formdata.append('_replyto', email);
  formdata.append('message', message);
  formdata.append('_subject:', 'Solicitud de carga de materias nuevas en Estudi');
  const requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
  } as RequestInit;

  fetch('https://formspree.io/f/meqyvgnn', requestOptions).catch((error) => console.error(error));
};
