import { Box, Text, Link, Heading } from '@chakra-ui/react';

const Terms = () => {
  return (
    <Box>
      <Box my={4}>
        <Text>
          Estudi (en adelante el “Titular”) a través de sus formularios de Google para solicitudes
          de adhesión y de su sitio web{' '}
          <Link color="blue.500" href="https://www.estudi.com.ar/" isExternal>
            https://www.estudi.com.ar/
          </Link>{' '}
          (en adelante el “Sitio”) incluyendo todos sus subdominios, comunica los Términos y
          Condiciones que regirán la relación contractual entre todo usuario profesor que ingrese al
          sistema Estudi, que engloban todos los sistemas informáticos de la plataforma Estudi
          incluyendo el Sitio (en adelante el “Sistema”). Asimismo, se informa en el presente la
          política de protección de datos de carácter personal (en adelante los “Datos Personales”)
          con el objeto que el Usuario determine en forma voluntaria si desea suministrar a Estudi
          sus datos personales en ocasión del uso del Sistema o compra de alguno de los productos o
          servicios ofrecidos por Estudi en el mismo.
        </Text>
      </Box>

      <Heading as="h2" size="md">
        Finalidad
      </Heading>

      <Box my={4}>
        <Text>
          La finalidad del Sistema es proporcionar ayuda a personas quienes desean conseguir un
          profesor particular (en adelante “Alumno”), pudiendo o no esta persona que ejecuta el rol
          de profesor ser docente o alumno avanzado (en adelante el “Profesor”) para carreras de
          grado universitarias según sus necesidades para un curso o materia de grado en particular
          (en adelante el “Curso”) en el territorio de la República Argentina.
        </Text>
      </Box>

      <Heading as="h2" size="md">
        Condiciones de uso y adhesión
      </Heading>

      <Box my={4}>
        <Heading as="h3" size="sm">
          1 - Aceptación de los términos y condiciones
        </Heading>

        <Text>
          Solicitar la adhesión al Sistema como Profesor, ya sea mediante formularios o de forma
          escrita con el Titular le otorga la condición de Usuario Profesor, e implica la aceptación
          completa irrevocable e incondicional de todas las cláusulas y condiciones de uso incluidas
          en las sección de Políticas de privacidad. Asimismo, declara bajo juramento tener 18 años
          de edad o más. Los padres, tutores o responsables de los menores de 18 años, son
          plenamente responsables por el uso del Sistema por parte de éstos.
        </Text>

        <Text>
          Si no estuviera conforme con todas y cada una de estas cláusulas y condiciones abstenerse
          de utilizar este Sistema.
        </Text>

        <Text>
          El acceso a este Sistema no supone, en modo alguno, el inicio de una relación comercial ni
          laboral con el Titular, el Sistema provee un servicio en el cual conecta al Profesor con
          Alumnos y recibe una compensación monetaria por el servicio prestado, el Titular es ajeno
          a las otras 2 partes involucradas y no tiene alcance o responsabilidad alguna sobre la
          relación que exista entre Alumno y Profesor, así como tampoco existe ninguna relación
          contractual ni de dependencia laboral entre el Titular y los Alumnos y Profesores.
        </Text>
      </Box>

      <Box my={4}>
        <Heading as="h3" size="sm">
          2 - Modificación de los Términos y condiciones
        </Heading>

        <Text>
          Estudi se reserva el derecho de realizar cambios en el Sistema, en las condiciones de uso
          y adhesión y en las notificaciones que correspondieren, en cualquier momento, y con la
          simple modificación del presente, comunicándose a través de publicación en el Sistema.
          Asimismo, Estudi se reserva el derecho en cualquier momento de interrumpir el servicio
          ofrecido, sin previo aviso al Profesor, en forma transitoria o definitiva, y sin necesitar
          consentimiento del Profesor.
        </Text>
      </Box>

      <Box my={4}>
        <Heading as="h3" size="sm">
          3 - Uso de contenido del Sistema
        </Heading>

        <Text>
          A través del Sistema, el Titular le facilita el acceso y la utilización de diversos
          contenidos que el Titular o sus colaboradores han publicado por medio de Internet.
        </Text>

        <Text>
          A tal efecto, usted está obligado y comprometido a NO utilizar cualquiera de los
          contenidos del Sistema con fines o efectos ilícitos, prohibidos en este Aviso Legal o por
          la legislación vigente, lesivos de los derechos e intereses de terceros, o que de
          cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal
          utilización de los contenidos, los equipos informáticos o los documentos, archivos y toda
          clase de contenidos almacenados en cualquier equipo informático propios o contratados por
          el Titular, de otros usuarios o de cualquier usuario de Internet.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Medidas de seguridad
      </Heading>

      <Box my={4}>
        <Text>
          Los datos personales que facilite al Titular pueden ser almacenados en bases de datos
          automatizadas o no, cuya titularidad corresponde en exclusiva al Titular, que asume todas
          las medidas de índole técnica, organizativa y de seguridad que garantizan la
          confidencialidad, integridad y calidad de la información contenida en las mismas de
          acuerdo con lo establecido en la normativa vigente en protección de datos. No obstante,
          debe ser consciente de que las medidas de seguridad de los sistemas informáticos en
          Internet no son enteramente fiables y que, por tanto el Titular no puede garantizar la
          inexistencia de virus u otros elementos que puedan producir alteraciones en los sistemas
          informáticos (software y hardware) del Profesor o en sus documentos electrónicos y
          ficheros contenidos en los mismos aunque el Titular pone todos los medios necesarios y
          toma las medidas de seguridad oportunas para evitar la presencia de estos elementos
          dañinos.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Datos personales
      </Heading>

      <Box my={4}>
        <Text>
          Estudi ha implementado todos los procesos requeridos a los efectos de Protección de los
          Datos Personales de los Profesores, con el objeto de evitar el mal uso, alteración, acceso
          no autorizado y/o robo de los Datos Personales suministrados por el Profesor a Estudi. Sin
          perjuicio de lo expresado en el presente, el Profesor acepta y reconoce que las medidas de
          seguridad del Sistema no son inviolables. El Profesor que suministre sus datos personales
          tendrá la posibilidad de ejercer su derecho de acceso a los mismos. También podrá
          rectificar los mismos. Toda información suministrada será tratada por Estudi conforme a
          los términos previstos en la Ley Nacional de Protección de Datos Personales Nª 25.326.
          Usted puede consultar toda la información relativa al tratamiento de datos personales que
          recoge el Titular en la sección de la Política de privacidad.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Contenidos
      </Heading>

      <Box my={4}>
        <Text>
          El Titular ha obtenido la información, el contenido multimedia y los materiales incluidos
          en el Sistema de fuentes que considera fiables, pero, si bien ha tomado todas las medidas
          razonables para asegurar que la información contenida es correcta, el Titular no garantiza
          que sea exacta, completa o actualizada. El Titular declina expresamente cualquier
          responsabilidad por error u omisión en la información contenida en el Sistema. Queda
          prohibido transmitir o enviar a través del Sistema cualquier contenido ilegal o ilícito,
          virus informáticos, o mensajes que, en general, afecten o violen derechos del Titular o de
          terceros. Los contenidos del Sistema tienen únicamente una finalidad informativa y bajo
          ninguna circunstancia deben usarse ni considerarse como oferta de venta, solicitud de una
          oferta de compra ni recomendación para realizar cualquier otra operación, salvo que así se
          indique expresamente. El Titular se reserva el derecho a modificar, suspender, cancelar o
          restringir el contenido del Sistema, los vínculos o la información obtenida a través del
          Sistema, sin necesidad de previo aviso. El Titular no es responsable de los daños y
          perjuicios que pudieran derivarse de la utilización de la información del Sistema.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Propiedad intelectual e industrial
      </Heading>

      <Box my={4}>
        <Text>
          Todos los derechos están reservados. Todo acceso a este Sistema está sujeto a las
          siguientes condiciones: la reproducción, almacenaje permanente y la difusión de los
          contenidos o cualquier otro uso que tenga finalidad pública o comercial queda expresamente
          prohibida sin el consentimiento previo expreso y por escrito de Titular.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Limitación de responsabilidad
      </Heading>

      <Box my={4}>
        <Text>
          La información y servicios incluidos o disponibles a través de este Sistema pueden incluir
          incorrecciones o errores tipográficos. De forma periódica el Titular incorpora mejoras y/o
          cambios a la información contenida y/o los servicios que puede introducir en cualquier
          momento. El Titular no declara ni garantiza que los servicios o contenidos sean
          interrumpidos o que estén libres de errores, que los defectos sean corregidos, o que el
          servicio o el servidor que lo pone a disposición estén libres de virus u otros componentes
          nocivos sin perjuicio de que el Titular realiza todos los esfuerzos en evitar este tipo de
          incidentes. El Titular declina cualquier responsabilidad en caso de que existan
          interrupciones o un mal funcionamiento de los Servicios o contenidos ofrecidos en
          Internet, cualquiera que sea su causa. Asimismo, el Titular no se hace responsable por
          caídas de la red, pérdidas de negocio a consecuencia de dichas caídas, suspensiones
          temporales de fluido eléctrico o cualquier otro tipo de daño indirecto que te pueda ser
          causado por causas ajenas a el Titular. Antes de tomar decisiones y/o acciones con base a
          la información incluida en el Sistema, el Titular le recomienda comprobar y contrastar la
          información recibida con otras fuentes.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Derecho de exclusión
      </Heading>

      <Box my={4}>
        <Text>
          El Titular se reserva el derecho a denegar o retirar el acceso al Sistema y los servicios
          ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos
          usuarios que incumplan cualquiera de las condiciones de este Aviso Legal.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Garantía de resultados
      </Heading>

      <Box my={4}>
        <Text>
          El Titular no se hará responsable por el éxito o fracaso de los Profesores en la
          aplicación de la información que reciban del Sistema o de los servicios que se ofrecen en
          el mismo. El Profesor será el único responsable de atraer a los Alumnos con su
          experiencia, formación y su perfíl. El Titular no se hará responsable por los problemas
          que pueda tener el Profesor por, correcto o no, del Sistema, de la información
          suministrada o por no tomar en cuenta problemas de salud, ideológicos, económicos o de
          cualquier otra índole que pudieran ser afectados por el contenido o servicios que se
          encuentre en de forma gratuita o no expuesto dentro del Sistema.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Formas de contacto
      </Heading>

      <Box my={4}>
        <Text>
          Si un Profesor desea contactar al Titular podrá enviar un correo electrónico a la
          dirección{' '}
          <Link color="blue.500" href="mailto://info@estudi.com.ar" isExternal>
            info@estudi.com.ar
          </Link>
          .
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Políticas de privacidad
      </Heading>

      <Box my={4}>
        <Text>
          Los datos personales serán utilizados para mantener el contacto y mantenerte al día de las
          actualizaciones, para enviarte la respuesta a la cuestión que solicitas al rellenar el
          formulario de adhesión, realizar encuestas de satisfacción y opinión sobre el Sistema y
          los temas tratados en el mismos, para informarte de las ofertas, o para proveer a los
          Alumnos que deseen tomar una clase con el Profesor los datos mínimos de contacto para
          conectar ambas partes (Profesor y Alumno). Puede usted ejercer los derechos de acceso,
          rectificación o supresión de los datos personales facilitados voluntariamente por usted a
          través de algún formulario web, o dirigiéndose a{' '}
          <Link color="blue.500" href="mailto://info@estudi.com.ar" isExternal>
            info@estudi.com.ar
          </Link>
          . Puede dejar de recibir estos emails en cualquier momento haciendo click en el link del
          pie de nuestros emails. Usamos SendGrid como nuestra plataforma de marketing. Al clickear
          debajo a suscribir, acepta que esta información sea transferida a SendGrid para su
          procesamiento. Aprenda más sobre las políticas de privacidad de SendGrid{' '}
          <Link color="blue.500" href="https://sendgrid.com/policies/privacy/" isExternal>
            aquí
          </Link>
          .
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Políticas de contratación
      </Heading>

      <Box my={4}>
        <Text>
          La compra de cualquier producto o servicio publicado a través del Sistema se encuentra
          sujeta a disponibilidad de stock, de la aprobación de identidad del Profesor, de la
          imputación correcta de la tarjeta de crédito o valores transferidos y a la aprobación por
          parte del Usuario de estos Términos y Condiciones. El profesor podrá optar por ser parte
          del Marketplace de Estudi donde el Titular recibe un cargo de servicio por la conexión
          entre Alumno y Profesor y el pago de la o las clases dictadas puede ser procesado por el
          Sistema en nombre del Profesor, o bien el sistema solo puede cobrar por la conexión entre
          Profesor y Alumno y el Profesor y Alumno pueden coordinar pagos fuera de la plataforma. El
          Titular no se responsabilizará por la relación comercial que exista entre Profesor y
          Alumnos ni de los pagos que se hagan o no entre ellos dentro del Sistema.
        </Text>
      </Box>
      <Box my={4}>
        <Heading as="h3" size="sm">
          1 - Medios de pago/cobro
        </Heading>

        <Text>
          Los pagos/cobros podrán realizarse a través de la plataforma de pago MercadoPago. La
          confirmación de la compra con tarjeta de crédito estará sujeta a la autorización del
          emisor de la misma. Todos los medios de pago están sujetos a que el importe sea
          debidamente acreditado y/o verificado. IMPORTANTE: Estudi no mantiene un vínculo
          asociativo con las corporaciones titulares de los sistemas de pago autorizados ni integra
          un grupo económico junto a las mismas. La utilización de los servicios de pagos ofrecidos
          por tales entidades se realiza por cuenta y riesgo del usuario o consumidor, no pudiendo
          en ningún caso imputarse a Estudi responsabilidad por los hechos u omisiones de tales
          entidades. Estudi no accede a los datos de las tarjetas de crédito u otros medios de pago
          empleados por el usuario, de modo tal que no garantiza que el tratamiento, almacenamiento
          y utilización de tales datos sea adecuado y se ajuste a las leyes de protección de datos
          personales (en Argentina, ley nro. 25326) y/o de defensa de los derechos del usuario o
          consumidor (en Argentina, ley 24240) y/o de tarjetas de crédito (en Argentina, Ley 25065).
        </Text>
      </Box>
      <Box my={4}>
        <Heading as="h3" size="sm">
          2 - Proceso de compra/venta
        </Heading>

        <Text>
          El resultado de la operación le será informado al Profesor y las operaciones exitosas
          podrán o no generar aviso visual en el Sistema, como resultado de la misma. A su vez, en
          casos exitosos podrá o no recibir correo electrónico informando el evento de reserva de
          clase con los datos de contacto del Alumno.
        </Text>
      </Box>
      <Box my={4}>
        <Heading as="h3" size="sm">
          3 - Cancelaciones y/o devoluciones
        </Heading>

        <Text>
          Para cancelar compras de productos o servicios en el Sistema, así como para solicitar una
          devolución deberá solicitarse por los canales de contacto del Sitio. El Titular se reserva
          el derecho para evaluar y decidir si acepta las solicitudes de cancelación o devolución en
          función de la evidencia y el caso presentado. En caso de Marketplace, el profesor es quien
          cobra al alumno y el Sistema lo hace en su nombre, en dichos casos las cancelaciones o
          devoluciones son estrictamente su responsabilidad. En casos donde el sistema cobra por la
          conexión entre Profesor y Alumno y el Profesor y Alumno pueden coordinar pagos fuera de la
          plataforma, en dicho caso el Titular solo se responsabiliza por ese cobro del servicio de
          reserva y bajo ningún concepto el Titular se responsabiliza de las cancelaciones o
          devoluciones de pagos realizados por fuera del Sistema entre Alumno y Profesor.
        </Text>
      </Box>
      <Box my={4}>
        <Heading as="h3" size="sm">
          4 - Marketplace
        </Heading>

        <Text>
          El Marketplace de Estudi se materializa a través del sistema de Mercadopago y el Profesor
          acepta los{' '}
          <Link
            color="blue.500"
            href="https://www.mercadopago.com.ar/ayuda/terminos-y-politicas_194"
            isExternal>
            Términos y Condiciones
          </Link>{' '}
          del mismo además de autorizar los{' '}
          <Link
            color="blue.500"
            href="https://auth.mercadopago.com.ar/authorization?client_id=6007665939567179&response_type=code&platform_id=mp"
            isExternal>
            términos de conexión al Marketplace
          </Link>
          , si durante su adhesión expresa su voluntad de ser parte del Marketplace.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Seguimiento de actividad
      </Heading>

      <Box my={4}>
        <Text>
          El usuario Profesor del Sistema conoce y acepta que Estudi podrá utilizar un sistema de
          seguimiento mediante la utilización de cookies. Las Cookies son pequeños archivos que se
          instalan en el disco rígido, con una duración limitada en el tiempo que ayudan a
          personalizar los servicios y a activar ciertas funcionalidades que sólo podrán estar
          disponibles mediante el empleo de Cookies. Se utilizará información obtenida mediantes las
          Cookies para analizar las páginas navegadas por el Usuario, las búsquedas realizadas,
          mejorar las iniciativas comerciales y promocionales, mostrar publicidad o promociones,
          banners de interés, perfeccionar la oferta de contenidos y artículos; también para
          promover y hacer cumplir las reglas y seguridad del sitio.
        </Text>
      </Box>
      <Heading as="h2" size="md">
        Avisos publicitarios
      </Heading>

      <Box my={4}>
        <Text>
          Cuando el Profesor hace “click” en avisos publicitarios o links de terceros e ingresa en
          otros sitios que no pertenecen a Estudi estará sujeto a los Términos y Condiciones de
          dichos sitios. El Profesor deberá leer detenidamente sus políticas de acceso y uso. Estudi
          no garantiza la legalidad, actualidad, calidad ni utilidad de los contenidos, operaciones
          e informaciones que se comuniquen, reproduzcan y/o realicen en sitios enlazados de
          terceros ni la ausencia de nocividad de tales contenidos o servicios, por lo que el
          Usuario exime de toda responsabilidad a Estudi por los contenidos incluidos en los
          referidos sitios o los servicios que en ellos se brindan o promocionan.
        </Text>
      </Box>
    </Box>
  );
};

export default Terms;
