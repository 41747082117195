import {
  FormControl,
  FormLabel,
  Button,
  FormHelperText,
  VStack,
  Stack,
  Checkbox,
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Input,
  Link
} from '@chakra-ui/react';
import { Formik, Field, Form, FormikProps } from 'formik';
import { DictatedCourse, useNewTeacherContext } from '../../contexts/NewTeacherProvider';
import { useEffect, useState } from 'react';
import {
  Course,
  Institution,
  fetchInstitutions,
  fetchCourses
} from '../../utils/clients/EstudiAdminClient';

interface FormValues {
  dictatedCourses: Map<string, DictatedCourse[]>;
  additionalCoursesMessage: string;
}

const CoursesData = () => {
  const { teacher, updateTeacher } = useNewTeacherContext();
  const [institutions, setInstitutions] = useState<Institution[]>([]);
  const [coursesByInstitution, setCoursesByInstitution] = useState<Map<string, Course[]>>(
    new Map()
  );

  const handleBack = async () => {
    await updateTeacher({ step: teacher.step - 1 });
  };

  const handleSubmit = async (values: any, actions: any) => {
    await updateTeacher({ step: teacher.step + 1, ...values });
    actions.setSubmitting(false);
  };

  useEffect(() => {
    const fetchInstitutionsData = async () => {
      const fetchedInstitutions = await fetchInstitutions();
      const sortedInstitutions = fetchedInstitutions.sort((a, b) => a.name.localeCompare(b.name));

      setInstitutions(sortedInstitutions);
    };

    void fetchInstitutionsData();
  }, []);

  useEffect(() => {
    const fetchCoursesData = async () => {
      const fetchedCourses = await fetchCourses();
      const coursesMap = fetchedCourses.reduce((map, course) => {
        const institutionId = course.institution.external_id;
        const courses = map.get(institutionId) || [];

        courses.push(course);

        return map.set(
          institutionId,
          courses.sort((a, b) => a.name.localeCompare(b.name))
        );
      }, new Map<string, Course[]>());

      setCoursesByInstitution(coursesMap);
    };

    void fetchCoursesData();
  }, []);

  return (
    <Formik
      initialValues={{
        dictatedCourses: teacher.dictatedCourses,
        additionalCoursesMessage: teacher.additionalCoursesMessage
      }}
      onSubmit={handleSubmit}>
      {(props: FormikProps<FormValues>) => {
        const { errors, isSubmitting, setFieldValue, values } = props;

        return (
          <Form>
            <VStack spacing="12px" margin="1rem">
              <Field name="dictatedCourses">
                {({ field }: { field: any }) => {
                  const handleCourseSelection = async (
                    institutionId: string,
                    courseId: string,
                    isSelected: boolean
                  ) => {
                    const updatedSelectedCourses = new Map(
                      field.value as Map<string, DictatedCourse[]>
                    );
                    const existingCourses = field?.value?.get(institutionId) || [];

                    if (isSelected) {
                      existingCourses.push({ external_id: courseId, notes: undefined });
                      updatedSelectedCourses.set(institutionId, existingCourses);
                    } else {
                      const filteredCourses = existingCourses.filter(
                        (dc: DictatedCourse) => dc.external_id !== courseId
                      );

                      if (filteredCourses.length > 0) {
                        updatedSelectedCourses.set(institutionId, filteredCourses);
                      } else {
                        updatedSelectedCourses.delete(institutionId);
                      }
                    }
                    await setFieldValue('dictatedCourses', updatedSelectedCourses);
                  };

                  const handleNotesOnChange = (
                    institutionId: string,
                    courseId: string,
                    notes: string
                  ) => {
                    const existingCourses = field?.value?.get(institutionId) || [];

                    const selectedCourseIndex = existingCourses.findIndex(
                      (dc: DictatedCourse) => dc.external_id === courseId
                    );

                    if (selectedCourseIndex !== -1) {
                      existingCourses[selectedCourseIndex].notes = notes;
                    }
                  };

                  const getNotesValue = (
                    dictatedCourses: DictatedCourse[] | undefined,
                    courseId: string
                  ): string | undefined => {
                    if (!dictatedCourses) {
                      return undefined;
                    }
                    const courseIndex = dictatedCourses.findIndex(
                      (dc: DictatedCourse) => dc.external_id === courseId
                    );

                    return dictatedCourses[courseIndex].notes;
                  };

                  return (
                    <FormControl isRequired isInvalid={!!errors.dictatedCourses}>
                      <FormLabel>Materias</FormLabel>
                      <FormHelperText display="flex" flexDirection="column" textAlign="left">
                        <b>Importante:</b>
                        <Link
                          color="blue.500"
                          href="https://drive.google.com/drive/folders/1txRK2dXrJl0RxSskR8P3twOry1TUoiQk"
                          isExternal>
                          Aca
                        </Link>{' '}
                        podrás ver material que hemos recopilado de las distintas materias para que
                        puedas evaluar si podes dar dicha materia.
                      </FormHelperText>
                      <FormHelperText textAlign="left" mb={3}>
                        - Marca las materias que sabes que podes dar de las disponibles en el
                        sistema <br />- Si hay alguna materia que no ves en el listado mas adelante
                        vas a poder agregarla <br />- [Opcional] podes agregar aclaraciones en las
                        materias seleccionadas
                      </FormHelperText>
                      <div>
                        {institutions.map((institution) => (
                          <Accordion allowMultiple width="100%" key={institution.external_id}>
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Box as="span" flex="1" textAlign="left">
                                    {institution.name}
                                  </Box>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                {coursesByInstitution
                                  ?.get(institution.external_id)
                                  ?.map((course) => (
                                    <Stack key={course.external_id}>
                                      <div
                                        style={{ display: 'flex', alignContent: 'space-evenly' }}>
                                        <Checkbox
                                          id={`${institution.external_id}-${course.external_id}`}
                                          mr={1}
                                          required={false}
                                          isChecked={
                                            field?.value
                                              ?.get(institution.external_id)
                                              ?.find(
                                                (dc: DictatedCourse) =>
                                                  dc.external_id === course.external_id
                                              ) || false
                                          }
                                          onChange={() =>
                                            handleCourseSelection(
                                              institution.external_id,
                                              course.external_id,
                                              !field?.value
                                                ?.get(institution.external_id)
                                                ?.map((dc: DictatedCourse) => dc.external_id)
                                                ?.includes(course.external_id) || false
                                            )
                                          }
                                        />
                                        <label
                                          htmlFor={`${institution.external_id}-${course.external_id}`}>
                                          {course.name}
                                        </label>
                                      </div>
                                      {field?.value
                                        ?.get(institution.external_id)
                                        ?.map((dc: DictatedCourse) => dc.external_id)
                                        ?.includes(course.external_id) && (
                                        <Input
                                          value={getNotesValue(
                                            field?.value?.get(institution.external_id),
                                            course.external_id
                                          )}
                                          placeholder="Aclaraciones..."
                                          mb={1}
                                          required={false}
                                          onChange={(e) =>
                                            handleNotesOnChange(
                                              institution.external_id,
                                              course.external_id,
                                              e.target.value
                                            )
                                          }
                                        />
                                      )}
                                    </Stack>
                                  ))}
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        ))}
                      </div>
                    </FormControl>
                  );
                }}
              </Field>
              <Field name="additionalCoursesMessage">
                {({ field }: { field: any }) => (
                  <FormControl mt={5}>
                    <FormLabel>Otras materias (opcional)</FormLabel>
                    <Input {...field} placeholder="Química orgánica en el ITBA" />
                    <FormHelperText>
                      Acá podes agregar materias y/o universidades que no viste en el listado. Tené
                      en cuenta que en primera instancia tu perfil se creará sin ellas.
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Stack display="flex" flexDirection="row" spacing={6}>
                <Button mt={4} variant="outline" colorScheme="blue" onClick={handleBack}>
                  Atras
                </Button>
                <Button
                  mt={4}
                  colorScheme="blue"
                  isLoading={isSubmitting}
                  type="submit"
                  isDisabled={!values.dictatedCourses.size}>
                  Siguiente
                </Button>
              </Stack>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CoursesData;
