import { FormControl, FormHelperText, FormLabel, VStack, Link } from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';

const Congrats = () => {
  return (
    <Formik initialValues={{}} onSubmit={() => {}}>
      {() => {
        return (
          <Form>
            <VStack spacing="12px" margin="1rem">
              <Field name="acceptTermsAndConditions">
                {({}: { field: any }) => (
                  <FormControl>
                    <FormLabel>Muchas gracias!</FormLabel>
                    <FormHelperText textAlign="left">
                      Formulario enviado correctamente, estaremos validando tus datos y te
                      avisaremos via mail todas las novedades de tu proceso para sumarte a Estudi,
                      cualquier duda particular también podes{' '}
                      <Link
                        color="blue.500"
                        href="https://api.whatsapp.com/send?phone=5491161297377"
                        isExternal>
                        escribirnos
                      </Link>
                      .
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Congrats;
