import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  FormHelperText,
  VStack,
  InputLeftElement,
  InputGroup,
  CheckboxGroup,
  Stack,
  Checkbox
} from '@chakra-ui/react';
import { Formik, Field, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useNewTeacherContext } from '../../contexts/NewTeacherProvider';

const PersonalSchema = Yup.object().shape({
  description: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(800, 'Demasiado largo!')
    .required('Requerido'),
  fullDescription: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(4000, 'Demasiado largo!')
    .required('Requerido'),
  pricePerHour: Yup.string().required('Requerido')
});

interface FormValues {
  description: string;
  fullDescription: string;
  pricePerHour: number;
  busyDays: string[];
}

const ProfileData = () => {
  const { teacher, updateTeacher } = useNewTeacherContext();

  const handleSubmit = async (values: any, actions: any) => {
    await updateTeacher({ step: teacher.step + 1, ...values });
    actions.setSubmitting(false);
  };

  const handleBack = async () => {
    await updateTeacher({ step: teacher.step - 1 });
  };

  return (
    <Formik
      initialValues={{
        description: teacher.description as string,
        fullDescription: teacher.fullDescription as string,
        pricePerHour: teacher.pricePerHour as number,
        busyDays: teacher.busyDays as string[]
      }}
      validationSchema={PersonalSchema}
      onSubmit={handleSubmit}>
      {(props: FormikProps<FormValues>) => {
        const { touched, errors, isSubmitting, setFieldValue } = props;

        return (
          <Form>
            <VStack spacing="12px" margin="1rem">
              <Field name="description">
                {({ field }: { field: any }) => (
                  <FormControl isRequired isInvalid={!!(errors.description && touched.description)}>
                    <FormLabel>Descripción Resumen</FormLabel>
                    <Input
                      {...field}
                      placeholder="Soy
                      estudiante de Ing. Química en el ITBA"
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                    <FormHelperText>
                      Esta es lo primero que ve sobre vos un estudiante cuando le aparecen todas las
                      opciones de profes. Apunta a no más de 20 palabras!
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field name="fullDescription">
                {({ field }: { field: any }) => (
                  <FormControl
                    isRequired
                    isInvalid={!!(errors.fullDescription && touched.fullDescription)}>
                    <FormLabel>Descripción Completa</FormLabel>
                    <Input {...field} placeholder="Doy clases particulares hace ..." />
                    <FormErrorMessage>{errors.fullDescription}</FormErrorMessage>
                    <FormHelperText>
                      Acá tenes espacio para contar como sos como profe para que los alumnos puedan
                      verlo en tu perfil.¿Cuándo comenzaste a dar clases? ¿Logros académicos? ¿Qué
                      equipo tenes para dar clases? ¿Qué puede hacer un alumno en tus clases?
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field name="pricePerHour">
                {({ field }: { field: any }) => (
                  <FormControl
                    isRequired
                    isInvalid={!!(errors.pricePerHour && touched.pricePerHour)}>
                    <FormLabel>Precio por hora de clase</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" color="gray.300" fontSize="1.2em">
                        $
                      </InputLeftElement>
                      <Input {...field} placeholder="Precio por hora" />
                    </InputGroup>
                    <FormErrorMessage>{errors.pricePerHour}</FormErrorMessage>
                    <FormHelperText>
                      En pesos argentinos y preferentemente número entero. Este precio podrá ser
                      modificado cuando lo consideres necesario
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field name="busyDays">
                {({ field }: { field: any }) => {
                  return (
                    <FormControl>
                      <FormLabel>Días bloqueados (fijos)</FormLabel>
                      <CheckboxGroup
                        value={field.value}
                        onChange={(e) => {
                          void setFieldValue('busyDays', e);
                        }}>
                        <Stack spacing={[1, 5]} direction={['column', 'row']}>
                          <Checkbox name="busyDays" value="MONDAY">
                            Lunes
                          </Checkbox>
                          <Checkbox name="busyDays" value="TUESDAY">
                            Martes
                          </Checkbox>
                          <Checkbox name="busyDays" value="WEDNESDAY">
                            Miércoles
                          </Checkbox>
                          <Checkbox name="busyDays" value="THURSDAY">
                            Jueves
                          </Checkbox>
                          <Checkbox name="busyDays" value="FRIDAY">
                            Viernes
                          </Checkbox>
                          <Checkbox name="busyDays" value="SATURDAY">
                            Sábado
                          </Checkbox>
                          <Checkbox name="busyDays" value="SUNDAY">
                            Domingo
                          </Checkbox>
                        </Stack>
                      </CheckboxGroup>
                      <FormHelperText>
                        Avisanos si hay días fijos donde NO das clases. Siempre vas a poder usar un
                        calendario que te vamos a dar para plasmar tu disponibilidad semana a
                        semana, aca la idea es que indiques SOLAMENTE los días fijos. Si todos los
                        días vas a tener horarios libres saltea este item.
                      </FormHelperText>
                    </FormControl>
                  );
                }}
              </Field>
              <Stack display="flex" flexDirection="row" spacing={6}>
                <Button mt={4} variant="outline" colorScheme="blue" onClick={handleBack}>
                  Atras
                </Button>
                <Button mt={4} colorScheme="blue" isLoading={isSubmitting} type="submit">
                  Siguiente
                </Button>
              </Stack>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProfileData;
