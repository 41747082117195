import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  FormHelperText,
  Text,
  VStack
} from '@chakra-ui/react';
import { Formik, Field, Form, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useNewTeacherContext } from '../../contexts/NewTeacherProvider';

const rePhoneNumber =
  /^(\+\d{0,4})\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const PersonalSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Requerido'),
  lastName: Yup.string()
    .min(2, 'Demasiado corto!')
    .max(50, 'Demasiado largo!')
    .required('Requerido'),
  email: Yup.string()
    .max(50)
    .email('Email invalido')
    .required('Requerido')
    .test('validateEmail', 'El email no es de Gmail', (value) => {
      const excludedDomains = [
        'hotmail.com',
        'hotmail.com.ar',
        'outlook.com',
        'outlook.com.ar',
        'live.com',
        'live.com.ar',
        'msn.com',
        'msn.com.ar',
        'email.msn.com',
        'email.msn.com.ar',
        'passport.com',
        'passport.com.ar',
        'passport.net',
        'icloud.com',
        'yahoo.com',
        'yahoo.com.ar'
      ];
      const emailParts = value.split('@');

      return !excludedDomains.includes(emailParts[1].toLowerCase());
    }),
  phoneNumber: Yup.string()
    .max(50)
    .matches(rePhoneNumber, 'Teléfono invalido')
    .required('Requerido')
});

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

const PersonalData = () => {
  const { teacher, updateTeacher } = useNewTeacherContext();

  const handleSubmit = async (values: any, actions: any) => {
    await updateTeacher({ step: teacher.step + 1, ...values });
    actions.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        firstName: teacher.firstName,
        lastName: teacher.lastName,
        email: teacher.email,
        phoneNumber: teacher.phoneNumber
      }}
      validationSchema={PersonalSchema}
      onSubmit={handleSubmit}>
      {(props: FormikProps<FormValues>) => {
        const { touched, errors, isSubmitting } = props;

        return (
          <Form>
            <VStack spacing="12px" margin="1rem">
              <Text display="flex" flexDirection="column" textAlign="left">
                <b>Recomendación:</b> Completa este formulario desde la computadora y no un
                dispositivo móvil para una mejor experiencia y visualización.
              </Text>
              <Field name="firstName">
                {({ field }: { field: any }) => (
                  <FormControl isRequired isInvalid={!!(errors.firstName && touched.firstName)}>
                    <FormLabel>Nombre</FormLabel>
                    <Input {...field} placeholder="Agustin" />
                    <FormErrorMessage>{errors.firstName}</FormErrorMessage>
                    <FormHelperText>
                      Lo que escribas es como aparecerá en la web a vista de los alumnos{' '}
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field name="lastName">
                {({ field }: { field: any }) => (
                  <FormControl isRequired isInvalid={!!(errors.lastName && touched.lastName)}>
                    <FormLabel>Apellido</FormLabel>
                    <Input {...field} placeholder="Osre" />
                    <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name="email">
                {({ field }: { field: any }) => (
                  <FormControl isRequired isInvalid={!!(errors.email && touched.email)}>
                    <FormLabel>Email</FormLabel>
                    <Input {...field} placeholder="agustin@gmail.com" />
                    <FormErrorMessage>{errors.email}</FormErrorMessage>
                    <FormHelperText>
                      Tiene que ser de Gmail (o institucional que use Gmail por detrás), para
                      gestionar y modificar cuando vos quieras tu calendario de ESTUDI y recibir
                      mails de confirmaciones de reservas de clases
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Field name="phoneNumber">
                {({ field }: { field: any }) => (
                  <FormControl isRequired isInvalid={!!(errors.phoneNumber && touched.phoneNumber)}>
                    <FormLabel>Teléfono Móvil</FormLabel>
                    <Input {...field} placeholder="+5491165132534" />
                    <FormErrorMessage>{errors.phoneNumber}</FormErrorMessage>
                    <FormHelperText>
                      Para WhatsApp sin espacios ni guiones y con código de area/país.
                    </FormHelperText>
                  </FormControl>
                )}
              </Field>
              <Text display="flex" flexDirection="column" textAlign="left">
                <b>Tranquilo:</b> No vamos a compartir tus datos personales. Solo compartiremos tus
                datos de contacto con aquellos alumnos que te compren clases para que puedan
                comunicarse con vos!
              </Text>
              <Button mt={4} colorScheme="blue" isLoading={isSubmitting} type="submit">
                Siguiente
              </Button>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default PersonalData;

// The below import defines which components come from formik
