import { createContext } from 'react';

export interface SetStepFunc {
  /** The callback for setting the error */
  (): void;
}
// declare const StepContext: React.Context<{
//   setStep: SetStepFunc;
// }>;

// const ThemeContext = createContext(null);
const StepContext = createContext<{ setStep: SetStepFunc }>({
  setStep: () => {}
});

export default StepContext;
