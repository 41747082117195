import { Box, Container, Hide, Progress, Show, Stack } from '@chakra-ui/react';
import {
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Image,
  Stepper,
  useSteps
} from '@chakra-ui/react';
import PersonalData from '../PersonalData';
import './App.css';
import ProfileData from '../ProfileData';
import PictureData from '../PictureData';
import CoursesData from '../CoursesData';
import { useNewTeacherContext } from '../../contexts/NewTeacherProvider';
import StepContext from '../../contexts/StepContext';
import { useEffect } from 'react';
import Confirmation from '../Confirmation';
import Congrats from '../Congrats';
import estudiLogo from '../../resources/estudi.jpeg';

function App() {
  const steps = [
    { title: 'Datos' },
    { title: 'Perfil' },
    { title: 'Imagen' },
    { title: 'Materias' },
    { title: 'Confirmar' }
  ];
  const { teacher } = useNewTeacherContext();

  const { activeStep, goToNext, setActiveStep } = useSteps({
    index: teacher.step,
    count: steps.length
  });

  useEffect(() => setActiveStep(teacher.step), [teacher]);

  const getActiveStepComponent = () => {
    switch (activeStep) {
      case 0:
        return <PersonalData />;
      case 1:
        return <ProfileData />;
      case 2:
        return <PictureData />;
      case 3:
        return <CoursesData />;
      case 4:
        return <Confirmation />;
      case 5:
        return <Congrats />;
      default:
        break;
    }
  };

  const max = steps.length - 1;
  const progressPercent = (activeStep / max) * 100;

  return (
    <div className="App">
      <Container maxW="container.sm">
        <Stack mb={5}>
          <Image src={estudiLogo} alt="Estudi Logo" width="60px" alignSelf="center" />
        </Stack>
        <Hide breakpoint="(max-width: 768px)">
          <Stepper mb={'1rem'} index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus
                    complete={<StepIcon />}
                    incomplete={<StepNumber />}
                    active={<StepNumber />}
                  />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </Hide>
        <Show breakpoint="(max-width: 768px)">
          <Progress value={progressPercent} mb={'1rem'} height="3px" width="full" zIndex={-1} />
        </Show>
      </Container>
      <Container centerContent>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden">
          <StepContext.Provider value={{ setStep: goToNext }}>
            {getActiveStepComponent()}
          </StepContext.Provider>
        </Box>
      </Container>
    </div>
  );
}

export default App;
